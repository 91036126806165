.otp_container {
  height: 100%; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 90);
  display: flex;
  flex-direction: column;
  background-color: aqua;
  overflow: hidden;
}
.otp-form-container {
  position: relative;
  background-color: #f4a6a8;
  height: 100%;
  overflow: scroll;
}

.otp-input {
  width: 3rem !important;
  height: 3rem;
  border: 1px solid #e8e8e8 !important;
  border-radius: 4px !important;
  margin-left: 4px;
}

@media only screen and (max-width: 377px) {
  .otp-input {
    width: 2.2rem !important;
    height: 2.2rem;
    border: 1px solid #e8e8e8 !important;
    border-radius: 4px !important;
    margin-left: 4px;
    /* background-color: #f4a6a8; */
  }
}

@media only screen and (max-width: 295px) {
  .otp-input {
    width: 2.1rem !important;
    height: 2.1rem;
    border: 0.5px solid #e8e8e8 !important;
    border-radius: 4px !important;
    margin-left: 4px;
    font-size: small;
    /* background-color: #f4a6a8; */
  }
}

.focus-style {
  border: 1px solid #f4a6a8 !important;
}

.focus-style:active {
  border: 1px solid #f4a6a8 !important;
}

.otp-input:active {
  border: 1px solid #f4a6a8 !important;
}
.spacer {
  padding: 16px;
}
.smallSpacer {
  padding: 8px;
}
