/* override browser default */
html,
body {
  margin: 0;
  padding: 0;
  overflow: visible;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 6px;
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}

body {
  font-family: "Montserrat" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100vh;
  margin: 0 auto;
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}

.lato-family {
  font-family: "Montserrat" !important;
}

:root {
  --primary-color: #e94d51;
  --secondary-color: rgba(255, 0, 0, 0.1);
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: "none";
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
