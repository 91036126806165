.mainContainer {
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
}

.logo {
  height: 6vh;
  width: 19vw;
  border-radius: 3px;
}

.content {
  height: 120px;
}
.innerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.innerContent1 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0em;
}
.innerContent2 {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
}
.outerContent {
  margin-top: 18px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  background-color: white;
  box-shadow: 4px 0px 8px 3px whitesmoke;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  padding: 8px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

@media (min-width: 960px) {
  .logo {
    height: 34px;
    width: 40px;
  }
  .outerContent {
    width: 70%;
    display: flex;
    justify-content: center;
  }
}
