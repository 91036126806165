.lato-family {
  font-family: "Montserrat" !important;
}

.banner-image-container {
  background-image: url("../../../../public/assets/banner-transparent.svg");
  background-color: var(--secondary-color);
  background-repeat: no-repeat;
  background-size: contain;
  height: 25rem;
  width: 100%;
}

/* .login-flex-jcc {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
} */
