.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 14rem;
  width: 100vw;
}

.logo-image {
  height: 60px;
  width: 70.75px;
}

.logo-tagline {
  font-family: "Montserrat";
  font-weight: 500;
  padding: 10px;
  text-transform: capitalize;
}
