.login_container {
  height: 100%; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
}

.form-container {
  position: relative;
}
