.webcam-container {
  position: relative;
  min-height: 100vh;
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  /* height: calc(var(--vh, 1vh) * 100); */
}
.webcam {
  height: 100%;
  width: 100%;
}
.camera-screen-container {
  height: calc(100vh - 25rem);
  text-align: center;
  /* margin-top: -55px; */
}

@media only screen and (min-width: 786px) {
  .camera-screen-container {
    height: auto;
  }
}
