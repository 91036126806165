.kycHome_container {
  height: 100%;
  /* height: calc(var(--vh, 1vh) * 100); */
  position: relative;
  display: flex;
  flex-direction: column;
}

.spacer {
  padding: 10px;
}
